<template>
    <div class="data-page">
        <div class="data-content">
            <div class="header">
                <div class="inner">
                    <div class="nav">
                        <!-- <div class="item" @click="">
                            品牌首页
                        </div>
                        <div class="item active" @click="">
                            实时直播
                        </div> -->
                        <!-- <div class="item" @click="$util.redirectTo('/pages/data/data?id='+room_info.id);">
							实时数据
						</div> -->
                    </div>
                    <div class="brand">
                        <div class="logo">
                            <img class="img" :src="$util.img(room_info.cover_img)"> </img>
                        </div>
                        <div class="brand-name">
                            {{ room_info.name }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="inner">
                    <div class="left">
                        <div class="card online-order">
                            <div class="title">
                                <font class="name">在线人数&订单数量</font>
                            </div>
                            <div class="con">
                                <div id="online-order-chart"></div>
                            </div>
                        </div>

                        <div class="card qrcode">
                            <div class="title">
                                <font class="name">扫码观看</font>
                            </div>
                            <div class="con">
                            </div>
                        </div>

                        <div class="card goods-rank">
                            <div class="title">
                                <font class="name">热销商品榜单</font>
                            </div>
                            <div class="con goods-list">
                                <!-- <marquee direction="up" behavior="alternate" scrollamount="3" scrolldelay="0" loop="-1"
                                    height="100%" hspace="1" vspace="0"> -->
                                    <div class="goods-item" v-for="(item, index) in goods_list" :key="index">
                                        <div class="goods-cover">
                                            <img class="image" :src="$util.img(item.sku_image)"> </img>
                                        </div>
                                        <div class="goods-info">
                                            <div class="goods-name">
                                                <font class="index">{{ index + 1 }}</font>
                                                <font class="name">{{ item.sku_name }}</font>
                                            </div>
                                            <div class="goods-bar">
                                                <div class="price">
                                                    <font class="currency">实收:</font>
                                                    <font class="shop-price">{{ item.pay_money }}</font>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="statis">
                                            <font class="num">销量：<font>{{item.goods_num}}</font> / 件</font>
                                            <font class="money">销售额：<font>{{item.goods_money}}</font> / 元</font>
                                        </div>
                                    </div>
                                <!-- </marquee> -->
                            </div>
                        </div>



                    </div>
                    <div class="center">
                        <div class="core-data core-data-box box">
                            <div class="title">
                                <font class="name">实时数据</font>
                                <font class="info">每60秒更新数据！</font>
                            </div>
                            <div class="con">
                                <div class="big-list">
                                    <div class="core-data-item">
                                        <font class="num">
                                            <!-- <animate-number from="0" to="1100" duration="2000" easing="easeOutQuad">
                                            </animate-number> -->
                                            {{statistics.room_people?statistics.room_people.online_num:'?'}}
                                        </font>
                                        <font class="desc">实时在线 / 人</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            <!-- <animate-number from="0" to="6444929" duration="2000" easing="easeOutQuad"
                                                :formatter="formatter">
                                            </animate-number> -->
                                            <!-- {{statistics.order.goods_money}} -->
                                            {{statistics.order?statistics.order.goods_money:'?'}}
                                        </font>
                                        <font class="desc">销售总额 / 元</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            <!-- {{statistics.order.goods_money}} -->
                                            {{statistics.order?statistics.order.pay_money:'?'}}
                                            <!-- <animate-number from="0" to="25000" duration="2000" easing="easeOutQuad">
                                            </animate-number> -->
                                        </font>
                                        <font class="desc">实收金额 / 元</font>
                                    </div>
                                </div>

                                <div class="small-list">

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.order?statistics.order.deal_order_num:'?'}}
                                        </font>
                                        <font class="desc">成交单数 / 单</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.order?statistics.order.deal_person_num:'?'}}
                                        </font>
                                        <font class="desc">成交人数 / 人</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.order?statistics.order.order_num:'?'}}
                                        </font>
                                        <font class="desc">总订单量 / 单</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.order?statistics.order.large_order_num:'?'}}
                                        </font>
                                        <font class="desc">大额订单 / 单
                                        </font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.order?statistics.order.order_conversion_rate:'?'}}
                                        </font>
                                        <font class="desc">下单转化率</font>
                                    </div>


                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.fission?statistics.fission.index:'?'}}
                                        </font>
                                        <font class="desc">裂变指数</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.fission?statistics.fission.Layers_num:'?'}}
                                        </font>
                                        <font class="desc">裂变层数</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.room_people?statistics.room_people.max_online_num:'?'}}
                                        </font>
                                        <font class="desc">最高在线 / 人</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.room_people?statistics.room_people.viewers_num:'?'}}
                                        </font>
                                        <font class="desc">累计观看 / 人</font>
                                    </div>

                                    <div class="core-data-item">
                                        <font class="num">
                                            {{statistics.subscribe ? statistics.subscribe.total:'?'}}
                                        </font>
                                        <font class="desc">预约人数 / 人</font>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div class="center-main">
                            <div class="card center-side-left rate">
                                <div class="title">
                                    <font class="name">总转化率</font>
                                </div>
                                <div class="con">
                                    <div id="rate">

                                    </div>
                                </div>
                            </div>
                            <div class="center-side-right">
                                <div class="card share">
                                    <div class="title">
                                        <font class="name">裂变统计</font>
                                    </div>
                                    <div class="con">
                                        <div class="data-item">
                                            <font class="num">{{fission.wechat_moments}}</font>
                                            <font class="desc">分享朋友圈</font>
                                        </div>
                                        <div class="data-item">
                                            <font class="num">{{fission.scan_code}}</font>
                                            <font class="desc">分享群</font>
                                        </div>
                                        <div class="data-item">
                                            <font class="num">{{fission.scan_code}}</font>
                                            <font class="desc">海报扫码</font>
                                        </div>
                                    </div>
                                </div>
                                <div class="card order">
                                    <div class="title">
                                        <font class="name">订单追踪</font>
                                    </div>
                                    <div class="con">
                                        <!-- <div class="order-no-pay">
                                            <font class="num">1699</font>
                                            <font class="desc">全部订单</font>
                                        </div> -->
                                        <div class="order-list">
                                            <div class="order-item" v-for="(item, index) in orderList.list" :key="index">
                                                    <div class="goods-cover">
                                                        <img class="image" :src="$util.img(item.sku_image)"> </img>
                                                    </div>
                                                    <div class="goods-info">
                                                        <div class="goods-name">
                                                            <font class="name">{{ item.sku_name }}</font>
                                                        </div>
                                                    </div>
                                                    <div class="statis">
                                                        <font class="num">用户：<font>{{item.nickname}}</font></font>
                                                        <font class="num">邀请人：<font>{{item.source_name}}</font></font>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="card">
                            <div class="title">
                                <font class="name">订单比例</font>
                            </div>
                            <div class="con">
                                <div id="order-proportion-chat"></div>
                            </div>
                        </div>


                        <div class="card invite">
                            <div class="title">
                                <font class="name">邀约排行</font>
                            </div>
                            <div class="con rank-list">

                                <div class="invite-rank">
                                    <!-- <marquee direction="up" behavior="alternate" scrollamount="5" scrolldelay="0"
                                        loop="-1" height="100%" hspace="" vspace="1"> -->
                                        <div class="item" v-for="(item, index) in inviteRankList">
                                            <img class="avatar"
                                                :src="item.headimg "
                                                mode="aspectFit"></img>
                                            <font class="name">
                                                <font class="index">{{ index + 1 }}</font> {{ item.nickname }}
                                            </font>
                                            <font class="count num">邀约人数：{{ item.count }}</font>
                                        </div>
                                    <!-- </marquee> -->
                                </div>

                            </div>
                        </div>

                        <div class="card consume">
                            <div class="title">
                                <font class="name">消费排行</font>
                            </div>
                            <div class="con rank-list">
                                <!-- <marquee direction="up" behavior="alternate" scrollamount="5" scrolldelay="0" loop="-1"
                                    height="100%" hspace="0" vspace="1"> -->
                                    <div class="consume-rank">
                                        <div class="item" v-if="consumeRankList"
                                            v-for="(item, index) in consumeRankList">
                                            <img class="avatar"
                                                :src="item.headimg"
                                                mode="aspectFit"></img>
                                                
                                            <font class="name">
                                                <font class="index">{{ index + 1 }}</font> {{ item.nickname }}
                                            </font>
                                            <font class="money num">消费金额：{{ item.money }}</font>
                                        </div>
                                    </div>
                                <!-- </marquee> -->
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'MashPushData',
    data() {
        return {
            room_info: {},
            goods_list: {},
            rank_list: {},
            onelineList:{},
            inviteRankList: {},
            consumeRankList: {},
            statistics:{},
            orderProportion:{},
            conversionRate:{},
            fission:{},
            orderList:{}
        };
    },
    components: {
        
    },
    async created() {
        await this.getRoomInfo()
        this.initData();
        setInterval(() => {
            this.initData()
        }, 60000);
    },
    mounted() {

    },
    methods: {
        //初始化 表格
        initData(){
            //获取直播间统计数据
            this.getStatistics();
            this.getOnlineList();
            this.getOrderProportion();
            this.getConversionRate();

            this.getGoodsList();
            this.getInviteRankList();
            this.getConsumeRankList();

            this.getFissionStatistics();

            this.getUnpaidOrders();
        },
        drawOnlineAndOrder(data) {
            let online = []
            let order = []
            for (let index = 0; index < data.length; index++) {
                // console.log(data[index]);
                online.push(data[index].online_num);
                order.push(data[index].order_num);
            }

            // 基于准备好的dom，初始化echarts实例
            let myChart = echarts.init(document.getElementById('online-order-chart'),'dark');
            // 指定图表的配置项和数据
            let option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                data: ['在线用户', '下单数量']
                },
                grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                top: '5%',
                containLabel: true
                },
                xAxis: {
                type: 'category',
                boundaryGap: true,
                splitLine:{
                    show: false,
                },
                data: ['0', '5', '10', '15', '20', '25', '30','35','40','45']
                },
                yAxis: {
                    splitLine:{
                    show: false,
                    },
                type: 'value'
                },
                series: [
                {
                    name: '下单数量',
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(58,77,233,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(58,77,233,0.3)'
                            }
                            ])
                        },
                    data: online
                },
                {
                    name: '在线用户',
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(124, 255, 178,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(124, 255, 178,0.3)'
                            }
                            ])
                        },
                    data: order
                }
                ]
            };
            myChart.setOption(option);
        },
        drawOrderProportion(data){
            let order = [
                { value: data.small, name: '小额单' },
                { value: data.middle, name: '千元单' },
                { value: data.large, name: '万元单' },
            ]
            let option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '0%',
                    left: 'center'
                },
                series: [
                    {
                        name: '订单比例',
                        type: 'pie',
                        radius: ['40%', '60%'],
                        itemStyle: {
                            borderRadius: 3,
                        },
                        label: {
                        show: false,
                        position: 'center'
                        },
                        emphasis: {
                        label: {
                            show: false,
                            fontSize: '40',
                            fontWeight: 'bold'
                        }
                        },
                        labelLine: {
                        show: false
                        },
                        data: order
                    }
                ]
            };
            
            // 基于准备好的dom，初始化echarts实例
            let myChart2 = echarts.init(document.getElementById('order-proportion-chat'),'dark');
            // 指定图表的配置项和数据
            myChart2.setOption(option);
        },
        drawConversionRate(data){
            
            // 基于准备好的dom，初始化echarts实例
            let myChart3 = echarts.init(document.getElementById('rate'),'dark');
            
            // 指定图表的配置项和数据
            let option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'item',
                },
                series: [
                    {
                        name: '总转化率',
                        type: 'funnel',
                        left: '10%',
                        top: 30,
                        bottom: 60,
                        width: '60%',
                        min: 0,
                        max: 50,
                        minSize: '0%',
                        maxSize: '100%',
                        sort: 'descending',
                        gap: 2,
                        data: [
                            { value: data.total_count, name: '总用户量' },
                            { value: data.buyer_count, name: '成交' },
                        ]
                    }
                ]
            };
            
            myChart3.setOption(option);
        },
        formatter: function (num) {
            return num.toFixed(2)
        },
        //获取在线人数列表
        getOnlineList(){
            this.$api.getOnlineList({
                room_id: this.$route.query.id,
                page:1,
                page_size:10
            }).then((res) => {
                if (res.code >= 0) {
                        this.onlineList = res.data
                        this.drawOnlineAndOrder(res.data);
                }
            });
        },
        //获取待支付订单列表
        getUnpaidOrders(){
            this.$api.getUnpaidOrders({
                room_id: this.$route.query.id,
                page:1,
                page_size:10
            }).then((res) => {
                if (res.code >= 0) {
                        this.orderList = res.data
                }
            });
        },
        //获取裂变统计
        getFissionStatistics(){
            this.$api.getFissionStatistics({
                room_id: this.$route.query.id
            }).then((res) => {
                if (res.code >= 0) {
                    this.fission = res.data
                }
            });
        },
        //订单比例
        getOrderProportion(){
            this.$api.getOrderProportion({
                room_id: this.$route.query.id
            }).then((res) => {
                if (res.code >= 0) {
                    this.orderProportion = res.data
                    this.drawOrderProportion(res.data);
                }
            });
        },
        //获取直播间统计总数据
        getStatistics(){
            this.$api.getStatistics({
                room_id: this.$route.query.id
            }).then((res) => {
                console.log(res);
                if (res.code >= 0) {
                     this.statistics = res.data
                }
            });
        },
        //获取总转化率 转化漏斗
        getConversionRate(){
            this.$api.getConversionRate({
                room_id: this.$route.query.id
            }).then((res) => {
                console.log(res);
                if (res.code >= 0) {
                    this.conversionRate = res.data
                    this.drawConversionRate(res.data);
                }
            });
        },
        getConsumeRankList() {
             this.$api.getConsumerRanking({
                room_id: this.$route.query.id
            }).then((res) => {
                
                if (res.code >= 0) {
                     this.consumeRankList = res.data.rank_list.list
                }
            });
            
        }, 
        getInviteRankList() {
            this.$api.getInviteRanking({
                room_id: this.$route.query.id
            }).then((res) => {
                
                if (res.code >= 0) {
                     this.inviteRankList = res.data.inviteRanking.list
                }
            });
        },
        //获取商品列表
        getGoodsList() {
            this.$api.getGoodsRanking({
                page: 1,
                page_size: 10, //暂时默认10，后期分页
                room_id: this.$route.query.id
            }).then((res) => {
                
                if (res.code >= 0) {
                    let data = res.data.list;
                    console.log(data);
                    data.forEach(item => {
                        item.sku_image = item.sku_image.indexOf(',') > -1 ? this.$util.img(
                            item.sku_image.split(',')[0]) : this.$util.img(item.sku_image)
                    })
                    console.log(data);
                    this.goods_list = data
                }
            });
        },
        //获取直播间信息
        async getRoomInfo() {
            await this.$api.getRoomInfo({
                room_id: this.$route.query.id
            }).then((res) => {
                
                if (res.code >= 0) {
                    this.room_info = res.data
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.data-page {
    height: 100vh !important;
    overflow: hidden !important;
    background-color: #2a2c37;
}

.inner {
    width: 1600px !important;
    max-width: none !important;
    margin: auto;
}


.data-page .header {
    height: 46px;
    background-image: linear-gradient(90deg, rgb(49, 51, 65) 0%, rgb(42, 44, 55) 100%);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
    border-top: 3px solid #c7a37a;
    position: relative;
    margin-bottom: 60px;
}

.data-page .header:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0;
    right: 0;
    background-image: url('https://resources.oz4.cn/mashang/push_img/header_line_bg.png');
    background-repeat: repeat-x;
    background-position: right bottom;
}

.data-page .header .inner {
    position: relative;
}

.data-page .header .inner .nav {
    position: absolute;
    left: 0;
    display: flex;
}

.data-page .header .inner .nav .item {
    color: #666985;
    height: 50px;
    line-height: 50px;
    margin-right: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.data-page .header .inner .nav .item:hover {
    color: #50A0FF;
    transition: 0.3s;
}

.data-page .header .inner .brand {
    width: 660px;
    height: 80px;
    margin: auto;
    background-image: url('https://resources.oz4.cn/mashang/data/brand_bg.png');
    background-repeat: no-repeat;
    margin-top: -3px;
    display: flex;
}

.data-page .header .inner .brand .logo {
    width: 50px;
    height: 50px;
    margin-left: 50px;
    margin-top: 12px;
    margin-right: 15px;
}

.data-page .header .inner .brand .logo .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.data-page .header .inner .brand .brand-name {
    line-height: 75px;
    height: 75px;
    color: #2a2c37;
    font-size: 26px;
}


.data-page .data-content .right {
    width: 380px;
    margin-left: 20px;
}


.data-page .data-content .content .inner {
    display: flex;
}

.data-page .data-content .content .left {
    width: 380px;
    margin-right: 20px;
}

.data-page .data-content .content .core-data-box {
    width: 800px;
    height: 380px;
    background-color: #2a2c37;
    background-image: linear-gradient(130deg, #50536d 0%, #282e46 100%);
    box-shadow: -5px 0px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 9px;
}

.data-page .data-content .content .core-data-box .title {
    height: 60px;
    line-height: 60px;
}

.data-page .data-content .content .core-data-box .title .name {
    padding-left: 20px;
    font-size: 16px;
    color: #9199bf;
    margin-right: 10px;
    font-weight: bold;
}

.data-page .data-content .content .core-data-box .title .info {
    font-size: 12px;
    color: #515a6e;
}

.data-page .data-content .content .core-data-box .con {
    padding: 30px;

}

.core-data-box .con .big-list {
    display: flex;
    margin-bottom: 40px;
    margin-top: 10px;
}

.core-data-box .con .small-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.core-data-box .con .core-data-item {
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.core-data-box .con .core-data-item .num {
    color: #fff;
    font-weight: bold;
}

.core-data-box .con .big-list .core-data-item {
    width: 33.3%;
    text-align: center;
}

.core-data-box .con .big-list .core-data-item .num {
    font-size: 32px;
    line-height: 32px;
    color: #adc1ff;
}

.core-data-box .con .big-list .core-data-item .desc {
    color: #6c7183;
}

.core-data-box .con .small-list .core-data-item {
    width: 20%;
    margin-bottom: 30px;
    text-indent: 30px;
}

.core-data-box .con .small-list .core-data-item .num {
    font-size: 22px;
    line-height: 22px;
    color: #adc1ff;
}

.core-data-box .con .small-list .core-data-item .desc {
    color: #6c7183;
}


.room-page .play {
    width: 100%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
    background-color: rgba(0, 0, 0, .4);
    border-radius: 6px;
    overflow: hidden;
}

.room-page .info {
    margin-left: 20px;
    width: 460px;
    min-width: 460px;
    min-height: 810px;
    background-color: rgba(0, 0, 0, .4);
    position: relative;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
    border-radius: 6rpx;
}

.room-page .info .title {
    font-size: 16px;
    line-height: 40px;
    color: #ddd;
    height: 40px;
    margin: 10px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}



.room-page .info .msg-bar {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
}


/* 基础卡片 */
.card {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    background-color: #36384a;
    border-radius: 6px;
}

.card .title {
    height: 50px;
    line-height: 50px;
}

.card .title .name {
    padding-left: 20px;
    font-size: 16px;
    color: #666985;
    margin-right: 10px;
    font-weight: normal;
}

.card .con {
    width: 100%;
    height: 100%;
    display: flex;

}

#online-order-chart {
    width: 100%;
    height: 100%;
}

#order-proportion-chat {
    width: 100%;
    height: 100%;
}

.goods-rank {
    height: 455px;
    margin-top: 20px;
}

.goods-rank .goods-list {
    padding: 10px 15px;
    height: 415px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.goods-rank .goods-item {
    display: flex;
    margin-bottom: 5px;
    background-color: #252834;
    padding: 10px;
    border-radius: 3px;
    color: #666985;
}

.goods-rank .goods-item .goods-name {
    color: #666985;
    width: 200px;
    
}

.goods-rank .goods-item .goods-name .index {
    background-color: #246CF9;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
}


.goods-rank .goods-item .goods-cover {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.goods-rank .goods-item .goods-cover .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}

.goods-rank .goods-item .goods-info {
    width: 130px;
}

.goods-rank .goods-item .goods-info .goods-name {}

.goods-rank .goods-item .statis {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.goods-rank .goods-item .statis .num font {
    color: #2EE491;
    margin-right: 5px;
}

.goods-rank .goods-item .statis .money font {
    color: #246CF9;
    margin-right: 5px;
}


.invite {
    margin-top: 15px;
    height: 330px;
}

.invite .invite-rank {
    width: 100%;
}

.invite .invite-rank .index {
    background-color: #2EE491;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

.consume {
    margin-top: 15px;
    height: 330px;
}

.consume .consume-rank {
    width: 100%;
}



.consume .consume-rank .index {
    background-color: #246CF9;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

.rank-list {
    padding: 0 15px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.rank-list .item {
    height: 36px;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 6px;
    line-height: 36px;
    box-sizing: content-box;
}

.rank-list .item .avatar {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-right: 15px;
}

.rank-list .item .name {
    display: inline-block;
    color: #fff;
    vertical-align: top;
}

.rank-list .item .num {
    color: #fff;
    float: right;
}

.center-main {
    display: flex;
    margin-top: 15px;
}

.center-side-left {
    width: 320px;
    height: 495px;
    margin-right: 15px;
}

.center-side-left #rate {
    width: 100%;
    height: 100%;
}

.center-side-right {
    width: 465px;
    height: 495px;
}

.share {
    height: 140px;
}

.share .con {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.share .data-item {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .1);
    width: 100px;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
}

.share .data-item .num {
    font-size: 16px;
    line-height: 32px;
    color: #adc1ff;
}

.share .data-item .desc {
    color: #6c7183;
}

.order {
    margin-top: 15px;
    height: 340px;
}

.order .con {
    display: block;
    padding: 10px 15px;
    height: 415px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;
}

.order .order-no-pay {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .1);
    width: 100px;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
}


.order .order-no-pay .num {
    font-size: 16px;
    line-height: 32px;
    color: #adc1ff;
}

.order .order-no-pay .desc {
    color: #6c7183;
}

.order .order-list  .order-item{
    display: flex;
    margin-bottom: 5px;
    background-color: #252834;
    padding: 10px;
    border-radius: 3px;
    color: #666985;
    position: relative;
}

.order .order-list  .order-item .goods-cover {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.order .order-list  .order-item .goods-cover .image {
    width: 100%;
    height: 100%;
}

.order .order-list  .order-item  .statis {
    position: absolute;
    bottom: 5px;
    right: 10px;
}
.order .order-list  .order-item  .statis font {
    margin-right: 5px;
}
.qrcode {
    margin-top: 15px;
}
</style>